// @ts-nocheck
import React, { useState } from "react";
import Layout from "../../Components/Layout";
import { useNavigate } from "react-router-dom";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../Utilities/Toaster";
import { useTranslation } from "react-i18next";
import { PostAPI } from "../../Utilities/PostAPI";
import { MiniLoader } from "../../Components/Loader";

export default function OrderTracking() {
  const [disabled, setDisabled] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate(); 
  const { t } = useTranslation();

  const trackingFunc = async () => {
    setDisabled(true);
    setLoader(true);
    if (trackId === "") {
      info_toaster("Please enter Tracking Id");
      setLoader(false)
      setDisabled(false)
    } else {
      const res = await PostAPI(`warehouse/checktrackingNumber/${trackId}`);
      console.log("🚀 ~ trackingFunc ~ res:", res);
      if (res?.data?.status === "1") {
        setDisabled(false);
        setLoader(false);
        if (res?.data?.data) {
          navigate("/booking-details", {
            state: {
              trackId: trackId,
            },
          });
          success_toaster("Order track successfully");
        } else {
          error_toaster("Invalid Tracking number");
          setLoader(false);
        }
      } else {
        setDisabled(false);
        error_toaster(res?.data?.error);
      }
    }
  };

  return (
    <Layout
      title={t("pages.orderTracking.title")}
      content={
        loader ? (
          <div className="top-2/4 left-2/4 fixed ">
            <MiniLoader />
          </div>
        ) : (
          <>
            <div className="flex items-center">
              <input
                value={trackId}
                onChange={(e) => setTrackId(e.target.value)}
                type="search"
                name="trackingId"
                id="trackID"
                placeholder={t("pages.orderTracking.inputPlaceholder")}
                className="text-base font-normal rounded-l-md border border-transparent w-96 px-4 py-2.5 2xl:py-3 focus:outline-none"
                autoComplete="off"
              />
              <button
                onClick={trackingFunc}
                disabled={disabled}
                className="cursor-pointer 2xl:text-lg font-semibold text-white bg-theme rounded-r-md px-4 py-2.5 border border-theme hover:bg-transparent hover:text-theme duration-200 placeholder:text-sm"
              >
                {t("pages.orderTracking.button")}
              </button>
            </div>
          </>
        )
      }
    />
  );
}
