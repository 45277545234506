import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GetAPI from "../../Utilities/GetAPI";
import Layout from "../../Components/Layout";
import Loader, { MiniLoader } from "../../Components/Loader";
import { BackButton } from "../../Utilities/Buttons";
import { t } from "i18next";
import StatusPill from "../../Components/StatusPill";
import OrderDetailsCard from "../../Components/OrderDetailsCard";
import NeverReceivedOrderDetailCard from "../../Components/NeverReceivedOrderDetailCard";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { PostAPI } from "../../Utilities/PostAPI";
import { error_toaster, success_toaster } from "../../Utilities/Toaster";

export default function NeverReceivedOrdersDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const { data } = GetAPI(
    `warehouse/packagesNeverReceivedDetails/${location?.state?.packageId}`
  );
  console.log("data:- ", data?.data, location?.state?.packageId);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleReceived = async () => {
    setLoader(true);
    const res = await PostAPI(
      `warehouse/OrderForNeverReceivedPkg/${location?.state?.packageId}`,
      {
        arrived: "arrived",
      }
    );
    if (res?.data?.status === "1") {
      setLoader(false);
      setModal(false);
      success_toaster(res?.data?.message);
      navigate("/never-received");
    } else {
      setLoader(false);
      error_toaster(res?.data?.message);
    }
  };

  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title={t("pages.details.awaitingConsolidationTitle")}
      search={false}
      content={
        <section className="space-y-6">
          <div className="flex justify-between">
            <BackButton name={t("pages.details.backButton")} />

            {/* <button
              onClick={() => {
                openModal(data?.data?.bookingId);
              }}
              className={`border border-theme bg-theme p-2 rounded-md text-white text-sm  ${
                data?.data?.bookingStatus?.id === 8
                  ? "bg-opacity-40 border-opacity-0"
                  : "bg-opacity-100 hover:text-theme hover:bg-transparent duration-200"
              }`}
              disabled={data?.data?.bookingStatus?.id === 8 ? true : false}
            >
              {data?.data?.bookingStatus?.id === 8
                ? t("pages.details.alreadyMeasurebtn")
                : t("pages.details.remeasureBtn")}
            </button> */}
          </div>

          <div className="bg-white py-8 px-10 rounded-lg">
            <div className="flex gap-5 [&>div]:min-w-[12.5%] overflow-auto pb-5">
              <StatusPill
                key={0}
                title={"Order Created"}
                pillStatus="completed"
                date={data?.data?.createdAt.slice(0, 10)}
                time={data?.data?.createdAt.slice(11, 19)}
              />
              <StatusPill
                key={1}
                title={"Never Received"}
                pillStatus="completed"
                date={data?.data?.updatedAt.slice(0, 10)}
                time={data?.data?.updatedAt.slice(11, 19)}
              />
            </div>
          </div>

          <div className="bg-white rounded-lg px-5 py-3 space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <h2 className="text-black font-semibold ">
                  {t("pages.details.trackingNum")} {data?.data?.trackingNum}
                </h2>
                {data?.data?.arrived === "arrived" ? (
                  <div className="bg-statusGreenBg text-statusGreen p-1 text-xs">
                    {t("pages.details.statusReceived")}
                  </div>
                ) : data?.data?.arrived === "neverArrived" ? (
                  <div className="bg-[#EF233C1F] text-[#EF233C] p-1 text-xs">
                    {t("pages.details.statusNeverReceived")}
                  </div>
                ) : (
                  <div className="bg-statusYellowBg text-statusYellow p-1 text-xs">
                    {t("pages.details.statusPending")}
                  </div>
                )}
              </div>
              <div>
                <button
                  onClick={() => setModal(true)}
                  className="border border-theme bg-theme p-2 rounded-md text-white text-sm  hover:text-theme hover:bg-transparent duration-200"
                >
                  Received
                </button>
              </div>
            </div>

            <div className="flex xl:justify-between flex-wrap gap-5">
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.companyName")}
                </h2>
                <p className="text-black text-sm capitalize">
                  {data?.data?.ecommerceCompany?.title}
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.name")}
                </h2>
                <p className="text-black text-sm">{data?.data?.name}</p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.email")}
                </h2>
                <p className="text-black text-sm">{data?.data?.email}</p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.phone")}
                </h2>
                <p className="text-black text-sm">{data?.data?.phone}</p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.weight")} (lbs)
                </h2>
                <p className="text-black text-sm">
                  {data?.data?.actualWidth === "0.00"
                    ? data?.data?.weight
                    : data?.data?.actualWeight}{" "}
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.size")} (inches) <sup>3</sup>
                </h2>
                <p className="text-black text-sm">
                  {data?.data?.actualHeight === "0.00" ||
                  data?.data?.actualLength === "0.00" ||
                  data?.data?.actualWidth === "0.00"
                    ? `${data?.data?.length} x ${data?.data?.width} x ${data?.data?.height}`
                    : `${data?.data?.actualHeight} x ${data?.data?.actualWidth} x ${data?.data?.actualLength}`}
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.eta")}
                </h2>
                <p className="text-black text-sm">{data?.data?.ETA}</p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.details.category")}
                </h2>
                <p className="text-black text-sm">
                  {data?.data?.category?.title}
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-sm text-tabColor">
                  {t("pages.deliveryDetails.instruction")}
                </h2>
                <p className="text-black text-sm">
                  {data?.data?.note ? data?.data?.note : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5">
            <NeverReceivedOrderDetailCard
              trackingId={data?.data?.booking?.trackingId}
              senderName={data?.data?.booking?.senderName}
              senderEmail={data?.data?.booking?.senderEmail}
              senderPhone={data?.data?.booking?.senderPhone}
              virtualBoxNum={data?.data?.booking?.customer?.virtualBox}
            />
          </div>
 
          <Modal onClose={closeModal} isOpen={modal} isCentered size={"lg"}>
            <ModalOverlay />
            <ModalContent bgColor="#F4F7FF">
              <ModalHeader padding={0}>
                <h2 className="text-theme text-center py-2 px-4 text-xl">
                  {/* {t("pages.modal.ReceivedPackage")} */}
                  Received Package
                </h2>
              </ModalHeader>
              <ModalCloseButton
                color="white"
                bgColor="#00528C"
                border="1px solid #00528C"
                _hover={{ bgColor: "transparent", color: "#00528C" }}
              />
              {loader ? (
                <div className="h-[332px]">
                  <MiniLoader />
                </div>
              ) : (
                <ModalBody padding={0}>
                  <div className="py-2 px-4 space-y-3">
                    <p className="text-center text-theme font-semibold">
                      Are you sure the Package is received?
                    </p>

                    <div className="flex justify-end gap-x-2 py-2">
                      <button
                        className="flex items-center gap-x-2 font-medium text-base hover:text-white hover:bg-theme py-1.5 px-5 border border-theme rounded 
                                bg-transparent text-theme duration-200"
                        onClick={() => setModal(false)}
                      >
                        {/* {t("pages.modal.cancel")} */}
                        Cancel
                      </button>
                      <button
                        className="flex items-center gap-x-2 font-medium text-base text-white bg-theme py-1.5 px-5 border border-theme rounded 
                                hover:bg-transparent hover:text-theme duration-200"
                        onClick={handleReceived}
                      >
                        {t("pages.details.sure")}
                      </button>
                    </div>
                  </div>
                </ModalBody>
              )}
            </ModalContent>
          </Modal>
        </section>
      }
    />
  );
}
